<template>
	<div class="layout-main">
		<layout-header/>
		<navigation/>
		<v-main class="my-4">
			<v-container fluid class="layout-main__container">
				<transition name="route" mode="out-in">
					<router-view :key="$route.path"/>
				</transition>
			</v-container>
		</v-main>

		<v-dialog
				v-model="isDialogChanges"
				width="500"
		>
			<v-card>
				<v-card-title>
					Изменения не сохранены
				</v-card-title>

				<v-card-text>
					У Вас есть несохраненные изменения, они будут утеряны. Продолжить?
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="isDialogChanges=false"
					>
						Нет
					</v-btn>
					<v-btn
							color="primary"
							text
							@click="isDialogChanges=false"
					>
						Да
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import Navigation from "./main/navigation";
    import LayoutHeader from "./main/layout-header";

    export default {
        name: "layout-main",
        components: {
            LayoutHeader,
            Navigation
        },
        computed: {
            isDialogChanges: {
                get() {
                    return this.$store.state.isDialogChanges;
                },
                set(value) {
                    return this.$store.state.session.isDialogChanges = value;
                }
            },
        }
    }
</script>

<style lang="scss">
	.layout-main {
		&__container {
			@include up($md) {
				padding-left: 24px;
				padding-right: 24px;
			}
		}
	}
</style>